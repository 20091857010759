import React from 'react';
import './App.css';
import { FaTwitter, FaTelegram } from 'react-icons/fa';

function App() {
  return (
    <div className="App">
      <div className="button-container">
        {/* Swap button opens a link */}
        <a 
          href="https://pump.fun/coin/C7MMC3PzfuUuZxmDtGG5Nbmtk95S3Pxb9ByvE3aEpump" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="button"
        >
          Swap
        </a>
        {/* Earn button now opens a link to Telegram bot */}
        <a 
          href="https://t.me/BulletTheDogbot" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="button"
        >
          Earn (Beta)
        </a>
      </div>
      <div className="social-media">
        <a href="https://x.com/BulletTheDogCom" target="_blank" rel="noopener noreferrer" className="social-icon">
          <FaTwitter />
        </a>
        <a href="https://t.me/BulletTheDog" target="_blank" rel="noopener noreferrer" className="social-icon">
          <FaTelegram />
        </a>
      </div>
    </div>
  );
}

export default App;